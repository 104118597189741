<template>
	<div 
		v-if="dashboardAccount"
		class="w-full h-full overflow-hidden flex flex-col">
		
		<header class="flex-none text-center py-4 bg-gray-darkest text-white">
			<img 
				v-if="dashboardAccount.logo"
				:src="fileToUrl(dashboardAccount.logo)"
				class="object-contain h-24 inline-block mb-4"
				style="max-width: 20rem;"
			/>
			<h1 
				v-else
				class="text-white text-4xl">
				{{dashboardAccount.name}}
			</h1>
			<h3 class="text-white opacity-50">Oppdatert {{fetchedAt | moment('lll')}}</h3>
		</header>
		
		<div 
			v-if="departments && !departments.length"
			class="flex-grow flex items-center justify-center">
			
			Beklager, fant ingen avdelinger å vise i Dashboard
		</div>
		<div 
			v-if="dashboard && departments && departments.length"
			class="flex-grow flex h-full">
			
			<section class="flex-1 border-r border-beige-dark flex bg-beige">
				<Overview 
					:stats="stats"
					mode="total"
					class="w-full pt-12"
					:departmentId="departmentIdCurrent"
				/>
			</section>
			
			<section class="flex-1 bg-beige shadow-2xl relative">
				<div class="h-1 absolute top-0 left-0 right-0">
					<div 
						v-for="d in departments.filter( d => d.departmentId == departmentIdCurrent)"
						:key="'animate_department_'+d.departmentId"
						class="bg-gray-darker h-1 animateDuration"
						:style="`animation-duration: ${duration}ms;`" 
					/>
				</div>
				
				<Overview 
					:stats="stats"
					mode="department"
					:departmentId="departmentIdCurrent"
				/>
			</section>
		</div>
		
		<div 
			v-if="error"
			class="fixed top-0 left-0">
			<pre>{{error}}</pre>
		</div>
		
		<TransactionNotice 
			@onTransaction="refreshDashboard"
			:showGiveKudo="false"
		/>
		
		<!-- <div class="fixed top-0 right-0 p-8">
			<button 
				@click.prevent="onNextDepartment()"
				class="button">
				
				Vis neste avdeling
			</button>
		</div> -->
	</div>
</template>

<script>

export default {
	components: {
		'Overview': () => import('@/components/stats/Overview.vue'),
		'TransactionNotice': () => import('@/components/TransactionNotice.vue'),
	},
	
	computed: {
		departmentIdCurrent(){
			// console.log('this.departments', this.departments);
			// if (!this.departments) {
			//     return
			// }
			return this.departments[ this.departmentCurrentIndex ].departmentId;
		},
		
		stats(){
			return {
				account: this.dashboardAccount,
				departments: this.departments,
				users: this.users,
				leaderboard: this.leaderboard,
				dateRange: this.dateRange,
				campaigns: this.campaigns,
			};
		},
	},
	
	data(){
		return {
			accessToken: null,
			error: null,
			departments: null,
			dashboardAccount: null,
			campaigns: null,
			departmentId: null,
			fetchedAt: null,
			departmentCurrentIndex: 0,
			intervalNextDepartmentFunction: null,
			intervalNextCamapaignFunction: null,
			intervalRefreshPageFunction: null,
			duration: 1000 * 10,
			users: null,
			leaderboard: null,
			dateRange: null,
		}
	},
	
	methods: {
		onNextDepartment() {
			if ( this.departmentCurrentIndex +1 == this.departments.length) {
				this.departmentCurrentIndex = 0;
			}
			else {
				this.departmentCurrentIndex++;
			}
		},	
		
		async getDashboard( accessToken ){
			try {
				const response = await this.$axios.get(process.env.VUE_APP_API_ENDPOINT +'/dashboard/'+accessToken);
				return response.data;
			} 
			catch (e) {
				this.error = { 
					type: 'error', 
					text: e.message || 'Kunne ikke hente Upsell dashboard'
				};
				
				console.error(e);
			} 
		},
		
		async refreshDashboard(){
			console.log('refreshDashboard START');
				const fetchedDashboardResponse = await this.getDashboard( this.accessToken );
				// console.log('fetchedDashboardResponse refreshed: ', fetchedDashboardResponse);
				this.fetchedAt = new Date().getTime();
				this.dashboard = fetchedDashboardResponse.dashboard;
				this.dashboardAccount = fetchedDashboardResponse.account;
				this.departments = fetchedDashboardResponse.departments;
				this.users = fetchedDashboardResponse.users;
				this.leaderboard = fetchedDashboardResponse.leaderboard;
				this.dateRange = fetchedDashboardResponse.dateRange;
				this.campaigns = fetchedDashboardResponse.campaigns;
				
				// if (fetchedDashboardResponse.campaigns) {
				// 	const timestamp = new Date().getTime();
				// 	this.campaigns = fetchedDashboardResponse.campaigns.filter( c => c.startAt <= timestamp);
				// }
				
			console.log('refreshDashboard STOP');
		},
	},
	
	async mounted(){
		this.accessToken = this.$route.params.accessToken;
		
		
		await this.refreshDashboard();
		// const fetchedDashboardResponse = await this.getDashboard( this.accessToken );
		// this.fetchedAt = new Date().getTime();
		// this.dashboard = fetchedDashboardResponse.dashboard;
		// this.dashboardAccount = fetchedDashboardResponse.account;
		// this.departments = fetchedDashboardResponse.departments;
		
		await this.$store.dispatch('webSocketConnect', this.dashboardAccount.accountId);
		
		this.intervalNextDepartmentFunction = setInterval( () => {
			this.onNextDepartment();
		}, this.duration); //10 seconds
		
		this.intervalRefreshPageFunction = setInterval( () => {
			// console.log('refresh!');
			this.refreshDashboard();
			// location.reload();
		// }, 1000 * 15); // every 15 seconds
		}, 1000 * 60 * 5); // 5 minutes
	},
	
	beforeDestroy() {
		if (this.intervalNextDepartmentFunction) {
			clearInterval(this.intervalNextDepartmentFunction);
		}
		
		if (this.intervalRefreshPageFunction) {
			clearInterval(this.intervalRefreshPageFunction);
		}
	},
}
</script>


<style lang="scss">
	html {
		font-size: .75vw;
	}
	
	.animateDuration {
		animation: animateLine linear;
	}
	
	@keyframes animateLine { 
		from {
			width: 0;
		}
		to { 
			width: 100%;
		} 
	}
	
</style>